<template>
  <div>
    <div class="top-btn">
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams" >
            <el-form-item   >
              <el-select   clearable filterable placeholder="选择职务" style="width:150px">
                <el-option label="全部" value="" ></el-option>
                <el-option label="上架中" :value="1"  ></el-option>
                <el-option label="已下架" :value="0" ></el-option>
              </el-select>
            </el-form-item> 
          <el-form-item >
             <el-input placeholder="请输入成员姓名"   ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" icon="el-icon-search"  >搜索</el-button>
          </el-form-item>
          <el-form-item class="fr">
            <el-button   size="small" icon="el-icon-plus" @click="dialogFormVisible = true" >新增</el-button>
            <el-button  size="small" @click="dialogFormVisible = true" >编辑</el-button>
            <el-button   size="small" >导入</el-button>
          </el-form-item>
      </el-form>
        
    </div>
    <div class="list params-wrap ">
      
      <paged-list v-model="queryParams" :data="pageData" :total="10"  >
        <template slot-scope="scope">
          <div class="box-style-d ">
            <div class="title cfx">
              <div class="box-l">
                <div class="title-name">{{ scope.row.name }}</div>
                <div class="title-school"> <span>安徽科技大学</span> </div>
              </div>
              <div class="box-r">
                <div class="title-img"></div>
              </div>
            </div>
            <div class="content cfx ">
                <ul>
                  <li><span>职务</span> 划水团长</li>
                  <li><span>电话</span> 18888888888</li>
                  <li><span >入职时间</span> 2021-01</li>
                  <li><span>毕业时间</span> 2021-01</li>
                </ul>
            </div>
          </div>
        </template>
        <template slot="control">
          <el-button type="primary" icon="el-icon-edit" circle  @click="drawer = true "></el-button>
          <el-button type="danger" icon="el-icon-delete" circle > </el-button>
        </template>
      </paged-list>
    </div>
    <!-- 编辑 -->
    <el-dialog title="添加成员"  center :visible.sync="dialogFormVisible">
      <el-form :model="form" label-width="100px">
        <el-form-item label="成员名称:" >
          <el-input style="width:300px" placeholder="请输入成员姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="学校名称:" >
          <el-select  clearable filterable placeholder="请选择学校" style="width:300px">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话:"  >
          <el-input style="width:300px" placeholder="请输入联系电话" v-model="team.a" clearable></el-input>
        </el-form-item>
        <el-form-item label="职务:" >
          <el-select  clearable filterable placeholder="请选择职务" style="width:300px">
            <el-option labellabel="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业时间:" >
           <el-date-picker type="date" placeholder="请选择毕业时间"  style="width:300px"></el-date-picker>
        </el-form-item>
        <el-form-item label="任职时间:" >
           <el-date-picker type="date" placeholder="请选择任职时间"  style="width:300px"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import PagedList from "@/components/common/PagedList";
  export default {
      components: {
      PagedList
      },
      data() {
        return {
          drawer:false,
          dialogFormVisible:false,
          queryParams: {
            schoolName:''
          },
          form:{
            a:[],
            b:[]
          },
          pageData:[
            { name:'神仙' },
             { name:'妖怪' }
          ],
          teamArr:[],
          team:{
            a:'',
            b:''
          },
          majorArr:[],
          
        }
      },
      methods:{
        opendrawer(){
          this.drawer = true
        },
         // 初始化地址、校区、专业
          initPage() {
            this.teamArr = [{ a: "", b: '',}];
            
          },
          // 添加校区，地址
          addTeam() {
            if (this.teamArr.filter((item) => !item.a || !item.b).length) {
              this.$message.warning("请完善校区信息再添加！");
              return;
            }
            this.teamArr.push({ team: "" });
          },
          // 删除校区 地址
          removeTeam(index) {
            this.teamArr = this.teamArr.filter((item, idx) => idx != index);
          },
          // 添加专业
          addMajor() {
            if (this.majorArr.filter((item) => !item.a ).length) {
              this.$message.warning("请完善专业信息再添加！");
              return;
            }
            this.majorArr.push({ major: "" });
          },
          // 删除专业
          removeMajor(index) {
            this.majorArr = this.majorArr.filter((item, idx) => idx != index);
          },
      },
      mounted() {
         this.initPage();
      },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.top-btn{margin: 10px 0px;}
/deep/ .el-dialog{width: 33%;}
/deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
/deep/ .el-drawer__header{ margin-bottom: 0px;}
.drawer{ margin: 0px 20px;
  .title{font-size: 20px;}
    .content{
        .depar{ margin-top: 20px;
            span{ color: $c-white; font-size: 14px; background: $c-success; padding: 5px 10px;  border-radius: 5px; }
        }
        .major{ margin-top: 20px; 
            span{ color: $c-9; font-size: 14px; }
        }
        .major-name{ margin-top: 10px;
            p{font-size: 14px; color: $c-2; }
        }
    }
}   

</style>